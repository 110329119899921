import React, { useCallback, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@involve-software/uikit';

import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useLoaderData, useParams } from 'react-router-dom';
import * as yup from 'yup';

import {
  DemocardPaymentStatus,
  DemocardSessionResponse,
  updateDemocardSession,
} from 'api/democard';

import CopyBlock from 'components/CopyBlock';
import { FormInput } from 'components/Form';
import { Responsive } from 'components/Responsive';
import { Tooltip } from 'components/Tooltip';
import { CardIcon } from 'components/UI/CardIcon';

import { isNumeric } from 'utils/common';
import { useFieldRules } from 'utils/hooks/validation';

import {
  findNextEmptyInput,
  formatCardHolderName,
  formatCardNumber,
  isCardDateNotExpired,
} from './helpers';
import * as Styled from './index.styled';

enum PaymentStatus {
  INIT = 'init',
  SUCCESS = 'success',
  FAIL = 'fail',
}

interface CardPaymentForm {
  cardNumber: string;
  expirationDate: string;
  cvv: string;
  cardHolder: string;
}

const CardPayment = () => {
  const params = useParams();

  const { icons, images } = useTheme();

  const { amount, currency, successUrl, failedUrl } =
    useLoaderData() as DemocardSessionResponse;

  const { t } = useTranslation('cardPayment');

  const [paymentStatus, setPaymentStatus] = useState(PaymentStatus.INIT);

  const { cardExpirationDate, cvvCode, cardHolder, cardNumber } =
    useFieldRules();

  const { control, handleSubmit, watch } = useForm<CardPaymentForm>({
    mode: 'onTouched',
    shouldFocusError: true,
    resolver: yupResolver(
      yup.object().shape({
        cardNumber: cardNumber,
        expirationDate: cardExpirationDate,
        cvv: cvvCode,
        cardHolder: cardHolder,
      })
    ),
    defaultValues: {
      cardNumber: '',
      expirationDate: '',
      cvv: '',
      cardHolder: '',
    },
  });

  // Ref for the form element
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmitCallback = useCallback(
    async (values: CardPaymentForm) => {
      try {
        const { data } = await updateDemocardSession(
          params.sessionId || '',
          values.cardNumber
        );

        switch (data.status) {
          case DemocardPaymentStatus.SUCCESS:
            successUrl && window.location.replace(successUrl);
            return setPaymentStatus(PaymentStatus.SUCCESS);
          default:
            failedUrl && window.location.replace(failedUrl);
            return setPaymentStatus(PaymentStatus.FAIL);
        }
      } catch (e) {
        setPaymentStatus(PaymentStatus.FAIL);
      }
    },
    [params.sessionId, failedUrl, successUrl]
  );

  return (
    <Styled.Container>
      {paymentStatus === PaymentStatus.INIT && (
        <Styled.PaymentCard>
          <Styled.SummTo>
            <h6>{t('paymentCard.amountToPay')}</h6>
            <h6>
              <strong>
                {amount} {currency.alias}
              </strong>
            </h6>
          </Styled.SummTo>
          <Styled.PaymentForm
            ref={formRef}
            onSubmit={handleSubmit(handleSubmitCallback)}
          >
            <div>
              <h6>{t('paymentCard.paymentDetails')}</h6>
              <div>
                <Responsive
                  desktopContent={
                    <FormInput
                      control={control}
                      label={t('paymentCard.cardNumber')}
                      name="cardNumber"
                      placeholder={t('paymentCard.cardNumberPlaceholder')}
                      onChange={(e) => {
                        e.target.value = formatCardNumber(e.target.value);
                      }}
                      addOnEnd={
                        <CardIcon cardNumber={parseInt(watch('cardNumber'))} />
                      }
                      maxLength={23}
                      autoFocus
                      type="text"
                      inputMode="numeric"
                    />
                  }
                  mobileContent={
                    <FormInput
                      control={control}
                      name="cardNumber"
                      label={t('paymentCard.cardNumber')}
                      placeholder={t('paymentCard.cardNumberMobilePlaceholder')}
                      onChange={(e) => {
                        e.target.value = formatCardNumber(e.target.value);
                      }}
                      addOnEnd={
                        <CardIcon cardNumber={parseInt(watch('cardNumber'))} />
                      }
                      maxLength={23}
                      autoFocus
                      type="text"
                      inputMode="numeric"
                    />
                  }
                />
                <Styled.PaymentFormRow>
                  <FormInput
                    control={control}
                    name="expirationDate"
                    label={t('paymentCard.expirationDate')}
                    placeholder={t('paymentCard.expirationDatePlaceholder')}
                    mask={'99 / 99'}
                    onChange={(e) => {
                      if (
                        /^(0[1-9]|1[0-2])\s*\/\s*([0-9]{2})$/.test(
                          e.target.value
                        ) &&
                        isCardDateNotExpired(e.target.value)
                      ) {
                        findNextEmptyInput(formRef);
                      }
                    }}
                  />

                  <FormInput
                    control={control}
                    name="cvv"
                    label={t('paymentCard.cvv')}
                    placeholder="CVV"
                    onChange={(e) => {
                      if (e.target.value && isNumeric(e.target.value)) {
                        findNextEmptyInput(formRef);
                      }
                    }}
                    addOnEnd={
                      <Tooltip
                        button={<icons.hint.component />}
                        tooltip={
                          <Styled.Hint>
                            <icons.cardCvv.component />
                            <p>{t('paymentCard.cvvCodeHint')}</p>
                          </Styled.Hint>
                        }
                      />
                    }
                    mask={'999'}
                  />
                </Styled.PaymentFormRow>

                <Responsive
                  desktopContent={
                    <FormInput
                      control={control}
                      name="cardHolder"
                      onChange={(e) => {
                        e.target.value = formatCardHolderName(
                          e.target.value
                        ).toUpperCase();
                      }}
                      label={t('paymentCard.cardHolder')}
                      placeholder={t('paymentCard.cardHolderPlaceholder')}
                      errorRelative
                    />
                  }
                  tabletContent={
                    <FormInput
                      control={control}
                      name="cardHolder"
                      onChange={(e) => {
                        e.target.value = formatCardHolderName(
                          e.target.value
                        ).toUpperCase();
                      }}
                      label={t('paymentCard.cardHolder')}
                      placeholder={t('paymentCard.cardHolderMobilePlaceholder')}
                      errorRelative
                    />
                  }
                />
              </div>
            </div>

            <Styled.SubmitButton fullWidth type="submit">
              {t('paymentCard.payNow')}
            </Styled.SubmitButton>
          </Styled.PaymentForm>
        </Styled.PaymentCard>
      )}

      {paymentStatus === PaymentStatus.SUCCESS && (
        <Styled.PaymentCard>
          <Styled.ResultPayment>
            <div>
              <img
                src={images.statuses.success.path}
                alt="successful_payment"
              />
              <h6>{t('result.paymentSuccess')}</h6>
            </div>
            <Styled.SubmitButton
              fullWidth
              onClick={() => setPaymentStatus(PaymentStatus.INIT)}
            >
              {t('result.restartFlow')}
            </Styled.SubmitButton>
          </Styled.ResultPayment>
        </Styled.PaymentCard>
      )}

      {paymentStatus === PaymentStatus.FAIL && (
        <Styled.PaymentCard>
          <Styled.ResultPayment>
            <div>
              <img src={images.statuses.fail.path} alt="successful_payment" />
              <h6>{t('result.paymentError')}</h6>
            </div>
            <Styled.SubmitButton
              fullWidth
              onClick={() => setPaymentStatus(PaymentStatus.INIT)}
            >
              {t('result.restartFlow')}
            </Styled.SubmitButton>
          </Styled.ResultPayment>
        </Styled.PaymentCard>
      )}

      <Styled.Guide>
        <div className="guide__info">
          <div className="title">
            <img
              className="hand"
              src={icons['handWaving'].path}
              alt="hand-waving"
            />
            <h5>{t('guide.howToTest')}</h5>
          </div>
          <p>{t('guide.followInstructions')}</p>
        </div>

        <div className="guide__details">
          <h6>{t('guide.enterCard')}</h6>
          <div className="list">
            <CopyBlock
              copyText={'4111 1111 1111 1111'}
              label={
                <p>
                  <Trans i18nKey="guide__details.successfulFlow">
                    For a <span className="success">successful</span> flow, use:
                  </Trans>
                </p>
              }
            />

            <CopyBlock
              copyText={'4200 0000 0000 0000'}
              label={
                <p>
                  <Trans i18nKey="guide__details.errorFlow">
                    For an <span className="danger">error</span> flow, use:
                  </Trans>
                </p>
              }
            />
          </div>
        </div>
      </Styled.Guide>
    </Styled.Container>
  );
};

export default CardPayment;
