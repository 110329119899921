import React from 'react';

import {
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
} from 'react-router-dom';

import ErrorBoundary from 'components/Errors/ErrorBoundary';
import Layout from 'components/Layout';

import BinancePayRouter from 'modules/binancepay';
import CactusPaymentRouter from 'modules/cactus-pay';
import CactusBanksPaymentRouter from 'modules/cactus-pay-banks';
import CardPaymentRouter from 'modules/card-payment';
import CardTransferRouter from 'modules/card-transfer';
import ContactSupportRouter from 'modules/contact-support';
import CryptoRouter from 'modules/crypto';
import EmanatRouter from 'modules/emanat';
import ErrorRouter from 'modules/error';
import AccessDeniedPage from 'modules/error/pages/AccessDenied';
import NotFoundPage from 'modules/error/pages/NotFound';
import MbankRouter from 'modules/mbank-transfer';
import NcpayRouter from 'modules/ncpay';
import PaycosRouter from 'modules/paycos';
import PaymentRouter from 'modules/payment';
import PlatipayRouter from 'modules/platipay';
import QPaymentsRouter from 'modules/qpayments';
import QrPaymentRouter from 'modules/qr-pay';
import ResultRouter from 'modules/result';
import SelectMethodRouter from 'modules/select-method';

import {
  AVAILABLE_LNGS,
  AVAILABLE_LNGS_VALIDATION,
  FALLBACK_LNG,
  type Language,
} from 'data/i18n';
import { i18nInstance } from 'utils/i18n';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <Layout>
          <Outlet />
        </Layout>
      }
      loader={({ params, request }) => {
        if (!AVAILABLE_LNGS_VALIDATION.includes(params.lang as Language)) {
          const { pathname: urlPathname, search } = new URL(request.url);

          const pathname = urlPathname === '/' ? '' : urlPathname;

          return redirect(
            `/${AVAILABLE_LNGS.includes(params.lang as Language) ? i18nInstance.language : FALLBACK_LNG}${pathname}${search}`
          );
        }

        i18nInstance.changeLanguage(
          AVAILABLE_LNGS.includes(params.lang) ? params.lang : FALLBACK_LNG
        );
        return null;
      }}
    >
      <Route path={`/:lang/*`} errorElement={<ErrorBoundary />}>
        {ErrorRouter}
        {PaymentRouter}
        {QrPaymentRouter}
        {CactusPaymentRouter}
        {CactusBanksPaymentRouter}
        {CardPaymentRouter}
        {NcpayRouter}
        {CardTransferRouter}
        {BinancePayRouter}
        {PaycosRouter}
        {EmanatRouter}
        {PlatipayRouter}
        {QPaymentsRouter}
        {ResultRouter}
        {MbankRouter}
        {SelectMethodRouter}
        {ContactSupportRouter}
        <Route path="crypto">{CryptoRouter}</Route>
        <Route path="access_denied" element={<AccessDeniedPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Route>
  )
);

export default router;
