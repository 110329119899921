import styled from 'styled-components/macro';

export const ScTooltip = styled.span`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  &.active {
    span.dropdown {
      opacity: 1;
      pointer-events: all;
    }
  }
  span.button {
    cursor: pointer;
    display: flex;
    align-items: center;
    white-space: nowrap;
    z-index: 2;
  }
  span.dropdown {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: max-content;
    max-width: 140px;
    position: absolute;
    text-align: center;
    background: white;
    border-radius: 4px;
    padding: 8px;
    cursor: pointer;
    font: ${({ theme }) => theme.typography['paragraph-small']};
    color: black;
    white-space: initial;
    pointer-events: none;
    opacity: 0;
    transition: 0.25s linear all;
    z-index: 1;
    @media (max-width: 768px) {
      max-width: 100%;
      padding: 12px;
    }
    &.top {
      bottom: calc(100% + 16px);
      &:before {
        bottom: -8px;
      }
    }
    &.bottom {
      top: calc(100% + 13px);
      &:before {
        top: -8px;
      }
    }
    &:before {
      position: absolute;
      width: 16px;
      height: 16px;
      background-color: white;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      content: '';
      left: 46%;
      @media (max-width: 768px) {
        top: -8px;
        left: 50%;
      }
      @media (max-width: 768px) {
        right: 20px;
      }
    }
    @media (max-width: 768px) {
      width: calc(100% - 32px);
      height: max-content;
      position: fixed;
      transform: initial;
      margin-top: 16px;
      top: 0;
      bottom: auto;
      left: 16px;
      z-index: 200;
    }
  }
`;
