import { factory, getAuthorization } from 'api/base';

import { RequestMethod } from 'utils/constants';

export enum DemocardPaymentStatus {
  SUCCESS = 1,
  REJECTED,
  WAITING,
}

export interface DemocardSessionResponse {
  amount: number;
  cardSent: boolean;
  currency: {
    alias: string;
    code: number;
  };
  description: string;
  failedUrl: string | null;
  lng: string;
  paymentId: string;
  returnUrl: string | null;
  status: DemocardPaymentStatus;
  successUrl: string | null;
}

export const getDemocardSession = factory(
  (r) => (session: string) =>
    r<any>(RequestMethod.get, `/democard/session`, undefined, {
      headers: getAuthorization(session),
    })
);

export const updateDemocardSession = factory(
  (r) => (session: string, card: string) =>
    r<{ status: DemocardPaymentStatus }>(
      RequestMethod.post,
      `/democard/session`,
      {
        card,
      },
      {
        headers: getAuthorization(session),
      }
    )
);
