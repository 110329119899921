import React, { FC } from 'react';

import { Control, Controller, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { IInput, Input } from 'components/UI/Input';

type FormInputBlockProps = IInput &
  Omit<UseControllerProps, 'render' | 'control' | 'error'> & {
    control: Control<any>;
  };

const FormInput: FC<FormInputBlockProps> = ({
  control,
  rules,
  name,
  error,
  defaultValue,
  onChange,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ field, fieldState: { error: fieldError } }) => (
        <Input
          {...field}
          {...rest}
          value={field.value}
          onChange={(e) => {
            onChange && onChange(e);
            field.onChange(e);
          }}
          error={fieldError?.message ? t(fieldError.message) : error}
        />
      )}
    />
  );
};

export default FormInput;
