import styled from 'styled-components/macro';

export const CopyBlock = styled.div`
  width: 100%;
`;

export const CopyField = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.radius['radius-fields']};
  border: 1px solid ${({ theme }) => theme.colors['border-main']};
  background: ${({ theme }) => theme.colors['field-01']};
  position: relative;
  padding: 14px;
  font: ${({ theme }) => theme.typography['paragraph-medium']};
  color: ${({ theme }) => theme.colors['text-primary']};

  svg {
    flex-shrink: 0;
  }
`;

export const CopyBlockLabel = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing['field-d-mrg-top']};
  color: ${({ theme }) => theme.colors['text-primary']};
  font: ${({ theme }) => theme.typography['label-small']};
`;

export const CopyBlockIcon = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 4px;
  transition: 0.3s ease-in-out all;
  opacity: 0.4;
  &:active {
    background: ${({ theme }) => theme.colors['field-01']};
  }
  &:hover {
    opacity: 1;
  }
`;

export const CopyBlockValue = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;

  & > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & > svg {
    margin-right: ${({ theme }) => theme.spacing.primitives['s-12']};
  }
`;
