import React, { FC, Fragment, useMemo } from 'react';

import { useTheme } from '@involve-software/uikit';

import amexCardIcon from 'assets/cards/amex_card.svg';
import maestroCardIcon from 'assets/cards/maestro_icon.svg';
import masterCardIcon from 'assets/cards/mastercard_icon.svg';
import unionCardIcon from 'assets/cards/unionpay_icon.svg';
import activeVisaCardIcon from 'assets/cards/visa_active_icon.svg';
import visaCardIcon from 'assets/cards/visa_icon.svg';

import { CardIconType } from './helpers/enum';
import { getCardIconTypeByNumber } from './helpers/getCardIconByNumber';

interface CardIconProps {
  cardNumber: number;
  isActive?: boolean;
}

const CardIcon: FC<CardIconProps> = ({ cardNumber, isActive }) => {
  const { icons } = useTheme();

  const cardIcons = useMemo(() => {
    return {
      [CardIconType.DEFAULT]: icons.card.path,
      [CardIconType.AMEX]: amexCardIcon,
      [CardIconType.VISA]: visaCardIcon,
      [CardIconType.MAESTRO]: maestroCardIcon,
      [CardIconType.MASTERCARD]: masterCardIcon,
      [CardIconType.UNIONPAY]: unionCardIcon,
    };
  }, [icons]);

  const activeCardIcons = useMemo(() => {
    return {
      [CardIconType.DEFAULT]: <icons.card.component />,
      [CardIconType.AMEX]: amexCardIcon,
      [CardIconType.VISA]: activeVisaCardIcon,
      [CardIconType.MAESTRO]: maestroCardIcon,
      [CardIconType.MASTERCARD]: masterCardIcon,
      [CardIconType.UNIONPAY]: unionCardIcon,
    };
  }, [icons]);

  return (
    <Fragment>
      <img
        src={
          isActive
            ? activeCardIcons[getCardIconTypeByNumber(cardNumber)]
            : cardIcons[getCardIconTypeByNumber(cardNumber)]
        }
        alt="card_icon"
      />
    </Fragment>
  );
};

export default CardIcon;
