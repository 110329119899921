import React, { InputHTMLAttributes, LegacyRef, forwardRef } from 'react';

import { useTheme } from '@involve-software/uikit';

import cx from 'classnames';
import InputMask, { ReactInputMask } from 'react-input-mask';

import { ScInput } from './styled';

export interface IInput extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | React.ReactNode;
  labelBottom?: string | React.ReactNode;
  error?: string;
  errorRelative?: boolean;
  addOnStart?: React.ReactNode;
  addOnEnd?: React.ReactNode;
  mask?: string;
}

export const Input = forwardRef<HTMLInputElement, IInput>(
  (
    {
      label,
      error,
      errorRelative,
      addOnStart,
      addOnEnd,
      mask,
      disabled,
      name,
      value,
      labelBottom,
      children,
      ...rest
    },
    ref
  ) => {
    const { icons } = useTheme();
    return (
      <ScInput
        className={cx({
          error: !!error,
          errorRelative: !!errorRelative,
          disabled: !!disabled,
          addOnStart: !!addOnStart,
          addOnEnd: !!addOnEnd,
          labelBottom: !!labelBottom,
        })}
      >
        {label && <label htmlFor={name}>{label}</label>}

        <div>
          {addOnStart && <div className="addOnStart">{addOnStart}</div>}

          {mask ? (
            <InputMask
              mask={mask}
              id={name}
              name={name}
              disabled={disabled}
              ref={ref as LegacyRef<ReactInputMask> | undefined}
              value={value}
              autoComplete={disabled ? 'off' : 'on'}
              data-lpignore={disabled ? 'off' : 'on'}
              {...rest}
            />
          ) : (
            <input
              id={name}
              name={name}
              ref={ref}
              disabled={disabled}
              value={value}
              autoComplete={disabled ? 'off' : 'on'}
              data-lpignore={disabled ? 'off' : 'on'}
              {...rest}
            >
              {children}
            </input>
          )}
          {addOnEnd && <div className="addOnEnd">{addOnEnd}</div>}
        </div>
        {error && (
          <span>
            <icons.error.component /> {error}
          </span>
        )}
        {!error && labelBottom && <p>{labelBottom}</p>}
      </ScInput>
    );
  }
);
