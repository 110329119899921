import { isFuture, parse } from 'date-fns';
import * as Yup from 'yup';

import { isNumeric } from 'utils/common';

export function useFieldRules() {
  const REQUIRED = 'validation:required';

  return {
    REQUIRED,
    num: Yup.number(),
    requiredNum: Yup.number()
      .required(REQUIRED)
      .transform((value) => (isNaN(value) ? undefined : value)),
    str: Yup.string(),
    requiredStr: Yup.string().required(REQUIRED),
    boolean: Yup.boolean().required(REQUIRED),
    cardNumber: Yup.string()
      .required(REQUIRED)
      .test('is-card', 'validation:cardNumber', function (value) {
        if (!value) return false;

        const code = parseInt(value.replace(/\s/g, ''));

        return !isNaN(code) && new RegExp(/^\d{16,19}$/).test(code.toString());
      }),
    cardHolder: Yup.string()
      .trim()
      .required(REQUIRED)
      .min(3, 'validation:cardHolder')
      .max(255, 'validation:cardHolder')
      .matches(/^[a-zA-Z0-9\s.-]{3,255}$/, 'validation:cardHolder'),
    cardExpirationDate: Yup.string()
      .required(REQUIRED)
      .matches(
        /^(0[1-9]|1[0-2])\s*\/\s*([0-9]{2})$/,
        'validation:invalidExpired' // Invalid format
      )
      .test('is-future-date', 'validation:invalidExpired', (value) => {
        if (!value) return false; // Required field

        const [month, year] = value.split('/').map((str) => str.trim());
        const expirationDate = parse(
          `${month}/01/${20 + year}`,
          'MM/dd/yyyy',
          new Date()
        );
        return isFuture(expirationDate);
      }),
    cvvCode: Yup.string()
      .required(REQUIRED)
      .test('is-cvv', 'validation:cvv', function (value) {
        if (!value) return false;

        return isNumeric(value) && value.length === 3;
      }),
    email: Yup.string()
      .required(REQUIRED)
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
        'validation:format'
      ),
  };
}
