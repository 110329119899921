import styled from 'styled-components/macro';

export const ScInput = styled.div`
  position: relative;
  width: auto;
  min-width: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: stretch;
  justify-content: flex-end;
  &.disabled {
    * {
      cursor: not-allowed;
    }
    & > label:nth-child(1) {
      color: ${({ theme }) => theme.colors['icon-disabled-subtle']};
    }
  }
  &.error {
    & > div:nth-child(2) {
      input {
        border: 1px solid ${({ theme }) => theme.colors.danger};
      }
    }
  }
  &.addOnEnd {
    & > div:nth-child(2) {
      input {
        padding-right: calc(12px * 2 + 24px);
      }
    }
  }
  &.addOnStart {
    & > div:nth-child(2) {
      input {
        padding-left: calc(12px * 2 + 24px);
      }
    }
  }
  & > label:nth-child(1) {
    color: ${({ theme }) => theme.colors['text-primary']};
    font: ${({ theme }) => theme.typography['label-medium']};
  }
  & > div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    input {
      transition: 0.25s ease-in-out;
      font: ${({ theme }) => theme.typography['paragraph-large']};
      padding: 12px;
      border-radius: ${({ theme }) => theme.radius['radius-fields']};
      border: 1px solid ${({ theme }) => theme.colors['border-main']};
      background-color: ${({ theme }) => theme.colors['field-01']};
      outline: none;
      color: ${({ theme }) => theme.colors['text-primary']};
      &::placeholder {
        color: ${({ theme }) => theme.colors['text-placeholder']};
      }
      &:hover {
        background-color: ${({ theme }) => theme.colors['field-01-hover']};
        border-color: ${({ theme }) => theme.colors['border-brand']};
      }
      &:focus-within {
        background-color: ${({ theme }) => theme.colors['field-01-focus']};
        border-color: ${({ theme }) => theme.colors['border-brand']};
      }
      &:disabled {
        background-color: ${({ theme }) => theme.colors['field-01-disabled']};
        color: ${({ theme }) => theme.colors['text-placeholder']};
        border-color: ${({ theme }) => theme.colors['border-disabled']};
        &::placeholder {
          color: ${({ theme }) => theme.colors['text-placeholder']};
        }
        & + div:nth-last-child(1) {
          svg path {
            fill: ${({ theme }) => theme.colors['icon-disabled-subtle']};
          }
        }
      }
    }
    & > div.addOnEnd {
      display: flex;
      position: absolute;
      right: 12px;
      opacity: 1;
    }

    & > div.addOnStart {
      display: flex;
      position: absolute;
      left: 12px;
      opacity: 1;
    }
  }
  & > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    position: absolute;
    top: calc(100% + 4px);
    color: ${({ theme }) => theme.colors['text-danger']};
    white-space: nowrap;
    font: ${({ theme }) => theme.typography['label-small']};
    svg {
      min-width: 16px;
    }
  }
  &.errorRelative > span {
    position: relative;
    top: auto;
    white-space: normal;
    align-items: normal;
  }
  &.labelBottom > span {
    position: static;
  }
  & > p {
    color: ${({ theme }) => theme.colors['text-primary']};
    font: ${({ theme }) => theme.typography['label-small']};
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
