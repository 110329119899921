import React, { FC } from 'react';

import cx from 'classnames';

import { useComponentVisible } from 'utils/hooks/useComponentVisibility';

import { ScTooltip } from './styled';
import { TooltipProps } from './type';

const Tooltip: FC<TooltipProps> = ({ button, tooltip, position = 'top' }) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const toggle = () => setIsComponentVisible((prev) => !prev);

  return (
    <ScTooltip className={cx(isComponentVisible && 'active')} ref={ref}>
      <span
        className="button"
        onClick={() => setIsComponentVisible(true)}
        onMouseEnter={toggle}
        onMouseLeave={toggle}
      >
        {button}
      </span>
      <span
        className={cx('dropdown', {
          top: position === 'top',
          bottom: position === 'bottom',
        })}
      >
        {tooltip}
      </span>
    </ScTooltip>
  );
};

export default Tooltip;
