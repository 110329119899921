import { CardIconType } from './enum';

export function getCardIconTypeByNumber(number: number): CardIconType {
  const cardNumber = number.toString();

  if (/^(34|37)/.test(cardNumber)) {
    return CardIconType.AMEX;
  }
  if (/^4/.test(cardNumber)) {
    return CardIconType.VISA;
  }
  if (/^(50|56|57|58|63|67)/.test(cardNumber)) {
    return CardIconType.MAESTRO;
  }
  if (/^62/.test(cardNumber)) {
    return CardIconType.UNIONPAY;
  }
  if (/^(51|52|53|54|55)/.test(cardNumber)) {
    return CardIconType.MASTERCARD;
  }

  return CardIconType.DEFAULT;
}
