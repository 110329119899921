import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';

import { size } from './MediaQuery';

interface ResponsiveProps {
  mobileContent?: React.ReactNode;
  tabletContent?: React.ReactNode;
  desktopContent?: React.ReactNode;
}

const Responsive: React.FC<ResponsiveProps> = ({
  mobileContent,
  tabletContent,
  desktopContent,
}) => {
  const { tablet, desktop } = size;

  const [displayContent, setDisplayContent] = useState<React.ReactNode | null>(
    null
  );

  const handleResize = useCallback(() => {
    const width = window.innerWidth;
    if (width < parseInt(tablet)) {
      setDisplayContent(mobileContent || tabletContent || desktopContent);
    } else if (width < parseInt(desktop)) {
      setDisplayContent(tabletContent || desktopContent || mobileContent);
    } else {
      setDisplayContent(desktopContent || tabletContent || mobileContent);
    }
  }, [mobileContent, tabletContent, desktopContent, tablet, desktop]);

  useEffect(() => {
    handleResize(); // Initialize display content on mount
    const resizeListener = () => handleResize();
    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
  }, [handleResize]);

  return <Fragment>{displayContent}</Fragment>;
};

export default memo(Responsive);
