import { RefObject } from 'react';

export function formatCardNumber(cardNumber: string) {
  // Remove any existing spaces and non-digit characters
  const cleanedNumber = cardNumber.replace(/\D/g, '');

  // Add a space after every 4 digits
  return cleanedNumber.replace(/(\d{4})(?=\d)/g, '$1 ');
}

export function isCardDateNotExpired(value: string | undefined) {
  if (!value) return false;

  // Extract month and year
  const [expiryMonth, expiryYear] = value
    .split('/')
    .map((v) => parseInt(v, 10));

  // Get current date
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear() % 100; // Get last two digits of the year
  const currentMonth = currentDate.getMonth() + 1; // Month is zero-indexed

  // Check expiration
  if (
    expiryYear < currentYear ||
    (expiryYear === currentYear && expiryMonth < currentMonth)
  ) {
    return false; // Card is expired
  }

  return true; // Card is not expired
}

// Function to find & focus on the next empty input
export function findNextEmptyInput(formRef: RefObject<HTMLFormElement>) {
  const inputs = formRef.current?.getElementsByTagName('input');

  // Ensure inputs exist and have a length property
  if (!inputs || inputs.length === undefined) return;

  for (let i = 0; i < inputs?.length; i++) {
    if (inputs[i].value === '' && !inputs[i].disabled && !inputs[i].hidden) {
      inputs[i].focus();
      return;
    }
  }
}

export function formatCardHolderName(cardHolderName: string) {
  return cardHolderName.replace(/[^\w. -]|[\u0400-\u04FF]/g, '').toUpperCase();
}
