import { Button, Card } from '@involve-software/uikit';

import { CopyBlockLabel, CopyField } from 'components/CopyBlock/index.styled';

import styled from 'styled-components/macro';
import { down } from 'utils/breakpoints';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 502px;
  margin: 0 auto;
  ${down('lg')} {
    width: 100%;
    flex-direction: column-reverse;
    gap: 16px;
  }
`;

export const PaymentCard = styled(Card)`
  width: 100%;
  max-width: 502px;
  ${down('lg')} {
    max-width: none;
    padding: 24px 16px;
  }
`;

export const SummTo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors['border-main']};
  & > h6 {
    color: ${({ theme }) => theme.colors['text-primary']};
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    font-family: 'Open Sans', sans-serif;
    &:nth-child(2) {
      text-align: right;
    }
    & > strong {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      font-family: 'Open Sans', sans-serif;
      color: ${({ theme }) => theme.colors['text-brand']};
    }
  }
`;

export const PaymentForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  & > div:nth-child(1) {
    padding: 12px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    & > h6 {
      margin: 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      font-family: 'Open Sans', sans-serif;
    }
    & > div {
      display: flex;
      flex-direction: column;
      gap: 28px;
    }
  }
`;

export const PaymentFormRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 28px 24px;
`;

export const SubmitButton = styled(Button)`
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  padding: 16px 24px;
`;

export const ResultPayment = styled.div`
  padding-top: 148px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 54px;
  ${down('lg')} {
    padding-top: 120px;
  }
  & > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    img {
      width: 120px;
      height: 120px;
    }
    h6 {
      margin: 0;
      color: ${({ theme }) => theme.colors['text-primary']};
      font-family: 'Open Sans', sans-serif;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      ${down('lg')} {
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%;
      }
    }
  }
`;

export const Hint = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px 4px;
  p {
    margin: 0;
  }
  ${down('md')} {
    flex-direction: row;
  }
`;

export const Guide = styled.div`
  position: absolute;
  left: calc(100% + 16px);
  width: 100%;
  max-width: 252px;
  border: 1px solid ${({ theme }) => theme.colors['border-main']};
  background-color: ${({ theme }) => theme.colors['background-01']};
  padding: 28px 24px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${down('lg')} {
    position: static;
    max-width: none;
    padding: 12px 16px;
  }
  & > .guide {
    &__info {
      display: flex;
      flex-direction: column;
      gap: 4px;
      ${down('lg')} {
        gap: 8px;
      }
      & > .title {
        display: flex;
        flex-direction: column;
        gap: 20px;
        ${down('lg')} {
          flex-direction: row;
          align-items: center;
          gap: 8px;
        }
        & > .hand {
          width: 32px;
          height: 32px;
          ${down('lg')} {
            width: 24px;
            height: 24px;
          }
        }
        h5 {
          margin: 0;
          font-family: 'Open Sans', sans-serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 140%;
          color: ${({ theme }) => theme.colors['text-secondary']};
        }
      }
      & > p {
        margin: 0;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        font-family: 'Open Sans', sans-serif;
        color: ${({ theme }) => theme.colors['text-subtle']};
      }
    }
    &__details {
      display: flex;
      flex-direction: column;
      gap: 8px;
      & > h6 {
        margin: 0;
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
      }
      & > div.list {
        display: flex;
        flex-direction: column;
        gap: 12px;
        ${CopyBlockLabel} {
          font-family: 'Open Sans', sans-serif;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          & > p {
            margin: 0;
            color: ${({ theme }) => theme.colors['text-secondary']};
            span.success {
              color: ${({ theme }) => theme.colors['text-success']};
            }
            span.danger {
              color: ${({ theme }) => theme.colors['text-danger']};
            }
          }
        }
        ${CopyField} {
          padding: 8px 12px;
          font-family: 'Open Sans', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%;
        }
      }
    }
  }
`;
