import React from 'react';

import { Card } from '@involve-software/uikit';

import { Route } from 'react-router-dom';

import WrongParamsError from 'components/Errors/WrongParams';

import { democardLoader } from './loaders';
import CardPayment from './pages/CardPayment';

export default (
  <Route
    path="democard/:sessionId"
    loader={democardLoader}
    element={<CardPayment />}
    errorElement={
      <Card style={{ width: '100%' }}>
        <WrongParamsError />
      </Card>
    }
  />
);
